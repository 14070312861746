<template>
  <v-app id="starter">

    <v-main>
      
    <section id="hero" class="pa-10">
      <v-container fluid>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-row align="center" justify="center">
              <v-col sm="8" class="mb-12">
                <v-img src="@/assets/logo.png" class="d-block ml-auto mr-auto" max-width="450px" />
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="8" class="white--text text-center">
                <h1 class="font-weight display-2 mb-2">Välkommen!</h1>
                <h1 class="font-weight-light">
                  Gossens Pizzeria<br>Ekebyvägen 2 Alunda
                </h1>
                <v-btn rounded dark href="tel:017410280" x-large color="blue darken-2" class="mt-10">
                  <v-icon class="mr-2">
                    mdi-phone
                  </v-icon>
                  0174-102 80
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section>
      <v-container fluid>
        <v-row align="center" justify="center" class="grey lighten-2">
          <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Meny</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="dialog = false"
            >
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-img src="@/assets/MenyStor.jpg" alt="Meny" class="d-block ml-auto mr-auto" height="100%"/>
      </v-card>
    </v-dialog>
  </v-row>
          <v-col cols="12">
            <v-img src="@/assets/meny2.png" alt=""  max-width="1200px" class="d-block ml-auto mr-auto" @click="dialog = true" style="cursor: pointer;" />
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section>
      <v-col cols="12" class="pa-0">
      <mapComponent></mapComponent>
      </v-col>
    </section>
    
    </v-main>

    <v-footer
      padless
    >
      <v-card
        flat
        tile
        width="100%"
        class="blue-grey darken-4 text-center"
      >
        <v-card-text>
          <v-btn
            v-for="icon in icons"
            :key="icon"
            class="mx-4"
            icon
          >
            <v-icon size="24px">
              {{ icon }}
            </v-icon>
          </v-btn>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="white--text">
          {{ new Date().getFullYear() }} — <strong>Gossens Pizzeria</strong>
        </v-card-text>
      </v-card>
    </v-footer>

  </v-app>
</template>

<script>
import MapComponent from './components/map'

export default {
  name: 'Landing',
  components: {
    MapComponent,
  },
  data() {
    return {
       dialog: false,
    };
  },
   

};

</script>

<style scoped>
#hero {
  background: 
  linear-gradient(to bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.1)),
  url('./assets/pizza.jpg');
  background-size: cover;
  background-position: bottom;
}


</style>

